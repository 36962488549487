import { Blinkloader } from '@a-it/imgproxy-blinkloader'

export default defineNuxtPlugin(() => {
  new Blinkloader({
    cdnBase: 'https://cdn.a-it.org',
    lazyload: true,
    webp: true,
    fadeIn: true,
    responsive: true,
  })
})